import {
  AppLayout,
  Button,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  FlashbarProps,
  FormField,
  Header,
  HelpPanel,
  Input,
  Link,
  Select,
  SelectProps,
  SpaceBetween,
  SplitPanel,
  TextContent,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import "@aws-amplify/ui-react/styles.css";
import {
  COGNITO_CONFIG,
  IAttribute,
  updateUserAttributes,
  userPool,
} from "auth/Cognito";
import { userActions } from "store/slices/user-slice";
import { IBasicInfo, Role } from "./Interface";

const ProfileMain = () => {
  const poolData = {
    UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
    ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
  };

  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);

  const [editMode, setEditMode] = useState(false);
  const [role, setRole] = useState("");
  const [selectedRole, setSelectedRole] = useState<SelectProps.Option>({});
  const [basicInfo, setBasicInfo] = useState<IBasicInfo>({} as IBasicInfo);
  const [inEditBasicInfo, setInEditBasicInfo] = useState<IBasicInfo>(
    {} as IBasicInfo
  );

  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = "#/sign-on";
    } else {
      console.log(cognitoUser);
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        dispatch(userActions.setUser({ username: cognitoUser.getUsername() }));
      });
      cognitoUser.getUserAttributes(function (err: any, result: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        console.log(result);
        const basicInfo: IBasicInfo = {} as IBasicInfo;
        result.forEach((attribute: { Name: string; Value: any }) => {
          if (attribute.Name === "custom:role") {
            setRole(attribute.Value);
          } else if (attribute.Name.startsWith("custom:")) {
            basicInfo[
              attribute.Name.replace("custom:", "") as keyof IBasicInfo
            ] = attribute.Value;
          }
        });
        setBasicInfo(basicInfo);
      });
    }
  }, [dispatch]);

  useEffect(() => {
    setSelectedRole({
      label: role,
      value: role,
    });
  }, [role]);

  useEffect(() => {
    setInEditBasicInfo(basicInfo);
  }, [basicInfo]);

  const onSave = () => {
    setEditMode(false);
    const attributeList: IAttribute[] = [];
    Object.keys(inEditBasicInfo).forEach((key) => {
      attributeList.push({
        Name: `custom:${key}`,
        Value: inEditBasicInfo[key as keyof IBasicInfo],
      });
    });
    attributeList.push({
      Name: "custom:role",
      Value: selectedRole.value || "",
    });

    updateUserAttributes(
      attributeList,
      () => {
        setRole(selectedRole.value || "");
        setNotifications([
          {
            type: "success",
            dismissible: true,
            content: "User attributes updated successfully.",
            id: "user_attributes_updated",
            onDismiss: () => {
              setNotifications(
                notifications.filter((n) => n.id !== "user_attributes_updated")
              );
            },
          },
        ]);
      },
      (err) => {
        setNotifications([
          {
            type: "error",
            dismissible: true,
            content: err.message || JSON.stringify(err),
            id: "error_updating_user_attributes",
            onDismiss: () => {
              setNotifications(
                notifications.filter(
                  (n) => n.id !== "error_updating_user_attributes"
                )
              );
            },
          },
        ]);
      }
    );
  };

  const actionButton = (editMode: boolean) => {
    if (editMode) {
      return (
        <SpaceBetween size="xs" direction="horizontal">
          <Button
            variant="normal"
            onClick={() => {
              setEditMode(false);
              setSelectedRole({
                label: role,
                value: role,
              });
              setInEditBasicInfo(basicInfo);
            }}
          >
            Cancel
          </Button>

          <Button variant="primary" onClick={onSave}>
            Save
          </Button>
        </SpaceBetween>
      );
    } else {
      return (
        <Button
          variant="normal"
          onClick={() => {
            setEditMode(true);
          }}
        >
          Edit
        </Button>
      );
    }
  };

  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/profile" />}
      notifications={<Flashbar items={notifications} />}
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <ContentLayout
          header={
            <Header variant="h1" info={<Link variant="info">Info</Link>}>
              Profile - {username}
            </Header>
          }
        >
          <SpaceBetween size={"l"}>
            <Container
              header={
                <Header
                  variant="h2"
                  // description="Container description"
                  actions={actionButton(editMode)}
                >
                  Basic Information
                </Header>
              }
            >
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="l">
                  <FormField label="Role">
                    <Select
                      selectedOption={selectedRole}
                      onChange={({ detail }) => {
                        setSelectedRole(detail.selectedOption);
                      }}
                      options={[
                        { label: Role.SELLER, value: Role.SELLER },
                        { label: Role.BUYER, value: Role.BUYER },
                      ]}
                      disabled={!editMode}
                    />
                  </FormField>

                  <FormField label="Business Name">
                    <Input
                      type="text"
                      value={inEditBasicInfo.businessName}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          businessName: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>

                  <FormField label="Business Address">
                    <Input
                      type="text"
                      value={inEditBasicInfo.businessAddress}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          businessAddress: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <FormField label="Industry">
                    <Input
                      type="text"
                      value={inEditBasicInfo.industry}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          industry: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>

                  <FormField label="Tax ID">
                    <Input
                      type="text"
                      value={inEditBasicInfo.taxId}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          taxId: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>
                </SpaceBetween>
                <SpaceBetween size="l">
                  <FormField label="Contact Name">
                    <Input
                      type="text"
                      value={inEditBasicInfo.contactName}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          contactName: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>

                  <FormField label="Contact Email">
                    <Input
                      type="text"
                      value={inEditBasicInfo.contactEmail}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          contactEmail: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>

                  <FormField label="Contact Phone">
                    <Input
                      type="text"
                      value={inEditBasicInfo.contactPhone}
                      onChange={(e) => {
                        setInEditBasicInfo({
                          ...inEditBasicInfo,
                          contactPhone: e.detail.value,
                        });
                      }}
                      disabled={!editMode}
                    />
                  </FormField>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        </ContentLayout>
      }
      splitPanel={
        <SplitPanel header="Split panel header">Split panel content</SplitPanel>
      }
    />
  );
};

export default ProfileMain;
