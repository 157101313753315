import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@cloudscape-design/global-styles/index.css";
import { Amplify } from "aws-amplify";
import { COGNITO_CONFIG } from "auth/Cognito";
import { Provider } from "react-redux";
import { store } from "store/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: COGNITO_CONFIG.USER_POOL_ID,
      userPoolClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
    },
  },
});

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
