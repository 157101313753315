import * as React from "react";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import {
  AppLayout,
  Box,
  BreadcrumbGroup,
  Checkbox,
  ColumnLayout,
  ContentLayout,
  DateInput,
  Flashbar,
  StatusIndicator,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { ApiClient } from "api/ApiClient";
import { ValueWithLabel } from "common/ValueWithLabel";
import { userPool } from "auth/Cognito";
import { useParams } from "react-router-dom";
import { VendorInfo } from "./VendorInfo";
import { Invoice, Status } from "./Model";

export const CreditApplication: React.FunctionComponent<{}> = ({}) => {
  const [businessName, setBusinessName] = React.useState("");
  const [businessAddress, setBusinessAddress] = React.useState("");
  const [contactName, setContactName] = React.useState("");
  const [contactEmail, setContactEmail] = React.useState("");
  const [contactPhone, setContactPhone] = React.useState("");
  const [taxId, setTaxId] = React.useState("");

  const [sellerVerifyLoading, setSellerVerifyLoading] = React.useState(false);
  const [productVerifyLoading, setProductVerifyLoading] = React.useState(false);

  const [sellerId, setSellerId] = React.useState("");
  const [sellerVerified, setSellerVerified] = React.useState(false);
  const [sellerInfo, setSellerInfo] = React.useState({} as any);
  const [productId, setProductId] = React.useState("");
  const [productVerified, setProductVerified] = React.useState(false);
  const [productInfo, setProductInfo] = React.useState({} as any);

  const [ToSConsent, setToSConsent] = React.useState(false);
  const [notifications, setNotifications] = React.useState([] as any[]);

  const [dateOfDue, setDateOfDue] = React.useState("");
  const [quantity, setQuantity] = React.useState("1");
  const [unitPrice, setUnitPrice] = React.useState("0");

  const [submitLoading, setSubmitLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  const { id } = useParams();

  React.useEffect(() => {
    if (id) setSellerId(id);
    const user = userPool.getCurrentUser();
    if (!user) {
      window.location.href = "#/sign-on";
    } else {
      user.getSession((err: any, session: any) => {
        if (err) {
          window.location.href = "#/sign-on";
        } else {
          user.getUserAttributes((err, attributes) => {
            if (err) {
              console.log(err);
              return;
            }
            if (attributes) {
              const attributeMap = attributes.reduce((map, attribute) => {
                map[attribute.getName()] = attribute.getValue();
                return map;
              }, {} as any);
              setBusinessName(attributeMap["custom:businessName"] || "");
              setBusinessAddress(attributeMap["custom:businessAddress"] || "");
              setContactName(attributeMap["custom:contactName"] || "");
              setContactEmail(attributeMap["custom:contactEmail"] || "");
              setContactPhone(attributeMap["custom:contactPhone"] || "");
              setTaxId(attributeMap["custom:taxId"] || "");
            }
          });
        }
      });
    }
  }, []);

  const handleVerifySeller = () => {
    console.log(sellerId);
    setSellerVerifyLoading(true);
    ApiClient.getInstance()
      .verifyUserExistence(sellerId)
      .then((response) => {
        setSellerVerifyLoading(false);
        if (response) {
          console.log(response);
          if (response["custom:role"] !== "Seller") {
            setNotifications([
              {
                type: "error",
                content: "Entered ID does not belong to a seller.",
                dismissible: true,
                id: "seller_verification_error",
                onDismiss: () => {
                  setNotifications(
                    notifications.filter(
                      (notification) =>
                        notification.id !== "seller_verification_error"
                    )
                  );
                },
              },
            ]);
            return;
          }
          const vendorInfo = Object.keys(response).reduce((map, key) => {
            map[key.replace("custom:", "")] = response[key];
            return map;
          }, {} as any);
          setSellerVerified(true);
          setSellerInfo(vendorInfo);
          setNotifications([
            {
              type: "success",
              content: "Seller ID verified.",
              dismissible: true,
              id: "seller_verification_success",
              onDismiss: () => {
                setNotifications(
                  notifications.filter(
                    (notification) =>
                      notification.id !== "seller_verification_success"
                  )
                );
              },
            },
          ]);
        }
      })
      .catch((error) => {
        setSellerVerifyLoading(false);
        setNotifications([
          {
            type: "error",
            content: error.message || JSON.stringify(error),
            dismissible: true,
            id: "seller_verification_error",
            onDismiss: () => {
              setNotifications(
                notifications.filter(
                  (notification) =>
                    notification.id !== "seller_verification_error"
                )
              );
            },
          },
        ]);
      });
  };

  const handleVerifyProduct = () => {
    setProductVerifyLoading(true);
    ApiClient.getInstance()
      .verifyProductExistence(productId)
      .then((response) => {
        setProductVerifyLoading(false);
        if (response) {
          setProductVerified(true);
          setProductInfo(response);
          setNotifications([
            {
              type: "success",
              content: "Product ID verified.",
              dismissible: true,
              id: "product_verification_success",
              onDismiss: () => {
                setNotifications(
                  notifications.filter(
                    (notification) =>
                      notification.id !== "product_verification_success"
                  )
                );
              },
            },
          ]);
        }
      })
      .catch((error) => {
        setProductVerifyLoading(false);
        setNotifications([
          {
            type: "error",
            content: error.message || JSON.stringify(error),
            dismissible: true,
            id: "product_verification_error",
            onDismiss: () => {
              setNotifications(
                notifications.filter(
                  (notification) =>
                    notification.id !== "product_verification_error"
                )
              );
            },
          },
        ]);
      });
  };

  const handleSubmit = () => {
    if (ToSConsent) {
      const username = userPool.getCurrentUser()?.getUsername();
      if (username) {
        const buyerInfo: VendorInfo = {
          userId: username || "",
          businessName,
          businessAddress,
          contactName,
          taxId,
          contactEmail,
          contactPhone,
        };
        const application: Invoice = {
          buyerInfo,
          sellerInfo,
          productId,
          buyerId: username,
          sellerId,
          status: Status.UNVERIFIED,
          dateOfIssue: new Date().toISOString().split("T")[0],
          dateOfDue,
          quantity: parseInt(quantity),
          unitPrice: parseFloat(unitPrice),
        };
        console.log(JSON.stringify(application));
        // ApiClient.getInstance()
        //   .sendUserConsent(username, true)
        //   .then((res) => {

        //   });
        setSubmitLoading(true);
        ApiClient.getInstance()
          .createCreditApplication(application)
          .then((res) => {
            setSubmitLoading(false);
            setSuccess(true);
            console.log(res);
            setNotifications([
              {
                type: "success",
                content: "Credit application submitted successfully.",
                dismissible: true,
                id: "submission_success",
                onDismiss: () => {
                  setNotifications(
                    notifications.filter(
                      (notification) => notification.id !== "submission_success"
                    )
                  );
                },
              },
            ]);
          })
          .catch((error) => {
            console.log(error);
            setSubmitLoading(false);
            setNotifications([
              {
                type: "error",
                content: error.message || JSON.stringify(error),
                dismissible: true,
                id: "submission_error",
                onDismiss: () => {
                  setNotifications(
                    notifications.filter(
                      (notification) => notification.id !== "submission_error"
                    )
                  );
                },
              },
            ]);
          });
      } else {
        setNotifications([
          {
            type: "error",
            content: "Consent failed to send. Please try again.",
            dismissible: true,
            id: "consent_error",
            onDismiss: () => {
              setNotifications(
                notifications.filter(
                  (notification) => notification.id !== "consent_error"
                )
              );
            },
          },
        ]);
      }
    } else {
      setNotifications([
        {
          type: "error",
          content: "Please consent to the credit check service.",
          dismissible: true,
          id: "consent_error",
          onDismiss: () => {
            setNotifications(
              notifications.filter(
                (notification) => notification.id !== "consent_error"
              )
            );
          },
        },
      ]);
    }
  };

  if (success) {
    return (
      <AppLayout
        breadcrumbs={
          <BreadcrumbGroup
            items={[
              { text: "Invoice", href: "#/invoice" },
              { text: "Credit Application", href: "#/invoice/application" },
              // { text: "Service", href: "#" },
            ]}
          />
        }
        navigation={<FluxSideNavigation activeHref="#/invoice" />}
        notifications={<Flashbar items={notifications} />}
        content={
          <ContentLayout>
            <Container
              header={<Header variant="h1">Application Submitted</Header>}
              footer={
                <Button variant="primary" href="#/invoice">
                  Back to Invoice
                </Button>
              }
            >
              <Box
                margin={{ horizontal: "xl" }}
                // textAlign="center"
                color="inherit"
              >
                <SpaceBetween size="m">
                  <p>
                    Your credit application has been submitted successfully. You
                    will receive an email notification once your application has
                    been reviewed.
                  </p>
                </SpaceBetween>
              </Box>
            </Container>
          </ContentLayout>
        }
      />
    );
  }

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Invoice", href: "#/invoice" },
            { text: "Credit Application", href: "#/invoice/application" },
            // { text: "Service", href: "#" },
          ]}
        />
      }
      navigation={<FluxSideNavigation activeHref="#/invoice" />}
      notifications={<Flashbar items={notifications} />}
      content={
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                  {submitLoading ? (
                    <StatusIndicator type="loading">Loading</StatusIndicator>
                  ) : (
                    "Submit"
                  )}
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">Credit Application Form</Header>}
          >
            <SpaceBetween direction="vertical" size="xl">
              <Container
                header={
                  <Header
                    variant="h2"
                    actions={
                      <Button
                        formAction="none"
                        variant="normal"
                        onClick={handleVerifySeller}
                      >
                        {sellerVerifyLoading ? (
                          <StatusIndicator type="loading">
                            Loading
                          </StatusIndicator>
                        ) : (
                          "Verify"
                        )}
                      </Button>
                    }
                  >
                    Confirm Seller Information
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Seller ID">
                    <Input
                      value={sellerId}
                      onChange={(e) => setSellerId(e.detail.value)}
                    />
                  </FormField>
                  {sellerVerified && <VendorInfo vendorInfo={sellerInfo} />}
                </SpaceBetween>
              </Container>
              <Container
                header={<Header variant="h2">Confirm Buyer Information</Header>}
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Business Name">
                    <Input
                      value={businessName}
                      onChange={(e) => setBusinessName(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Business Address">
                    <Input
                      value={businessAddress}
                      onChange={(e) => setBusinessAddress(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Contact Name">
                    <Input
                      value={contactName}
                      onChange={(e) => setContactName(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Contact Email">
                    <Input
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Contact Phone">
                    <Input
                      value={contactPhone}
                      onChange={(e) => setContactPhone(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Tax ID">
                    <Input
                      value={taxId}
                      onChange={(e) => setTaxId(e.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
              <Container
                header={
                  <Header
                    variant="h2"
                    actions={
                      <Button
                        formAction="none"
                        variant="normal"
                        onClick={handleVerifyProduct}
                      >
                        {productVerifyLoading ? (
                          <StatusIndicator type="loading">
                            Loading
                          </StatusIndicator>
                        ) : (
                          "Verify"
                        )}
                      </Button>
                    }
                  >
                    Confirm Product Information
                  </Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Product ID">
                    <Input
                      value={productId}
                      onChange={(e) => setProductId(e.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
              <Container
                header={
                  <Header variant="h2">Confirm Payment Information</Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <FormField label="Date of Due">
                    <DateInput
                      value={dateOfDue}
                      onChange={(e) => setDateOfDue(e.detail.value)}
                      placeholder="YYYY/MM/DD"
                    />
                  </FormField>
                  <FormField label="Quantity">
                    <Input
                      inputMode="numeric"
                      value={quantity}
                      onChange={(e) => setQuantity(e.detail.value)}
                    />
                  </FormField>
                  <FormField label="Unit Price">
                    <Input
                      inputMode="decimal"
                      value={unitPrice}
                      onChange={(e) => setUnitPrice(e.detail.value)}
                    />
                  </FormField>
                </SpaceBetween>
              </Container>
              <Container
                header={
                  <Header variant="h2">Credit Check Service Consent</Header>
                }
              >
                <SpaceBetween direction="vertical" size="l">
                  <Box>
                    <p>
                      By checking the box below, you consent to our credit check
                      service to verify your credit history.
                    </p>
                  </Box>
                  <Checkbox
                    onChange={({ detail }) => setToSConsent(detail.checked)}
                    checked={ToSConsent}
                  >
                    I consent to the credit check service.{" "}
                  </Checkbox>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Form>
        </form>
      }
    />
  );
};
