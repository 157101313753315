import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";

export const ProductCreator = () => {
  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/product" />}
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Product", href: "#/product" }]} />
      }
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <Button variant="normal" href="#/product">
                  Back
                </Button>
              }
            >
              Overview
            </Header>
          }
        >
          <Container
            header={<Header variant="h1">Create Product</Header>}
          ></Container>
        </ContentLayout>
      }
    />
  );
};
