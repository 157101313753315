import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  username?: string;
  accessToken?: string;
  idToken?: string;
  email?: string;
}

const initialState: UserState = {
  username: undefined,
  accessToken: undefined,
  idToken: undefined,
  email: undefined,
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.username = action.payload.username;
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
    },
    logout(state) {
      state.username = undefined;
      state.accessToken = undefined;
      state.idToken = undefined;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setIdToken(state, action) {
      state.idToken = action.payload;
    },
    setUsername(state, action) {
      state.username = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    signOut(state) {
      state.username = undefined;
      state.accessToken = undefined;
      state.idToken = undefined;
      state.email = undefined;
    },
  },
});

export const userActions = userSlice.actions;
const userReducer = userSlice.reducer;
export default userReducer;
