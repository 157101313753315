import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Header,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { ProductTable } from "./ProductTable";
import { useEffect, useState } from "react";
import { getUserRole, userPool } from "auth/Cognito";

export const ProductMain = () => {
  const [role, setRole] = useState("");

  useEffect(() => {
    getUserRole(
      (role) => setRole(role),
      () => (window.location.href = "#/sign-on"),
      (err) => console.log(err)
    );
  }, []);

  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/product" />}
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Product", href: "#/product" }]} />
      }
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <Button variant="primary" href="#/product/create">
                  Add Product
                </Button>
              }
            >
              Overview
            </Header>
          }
        >
          <ProductTable items={[]} />
        </ContentLayout>
      }
    />
  );
};
