import {
  CognitoUserPool,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { userPool } from "auth/Cognito";
import axios, { AxiosInstance } from "axios";
import { Invoice } from "invoice/Model";

export class ApiClient {
  private static instance: ApiClient;
  private axiosInstance: AxiosInstance;
  private userPool: CognitoUserPool;

  private constructor() {
    this.axiosInstance = axios.create();
    this.userPool = userPool;
  }

  public static getInstance(): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient();
    }

    return ApiClient.instance;
  }

  private async getAccessToken() {
    const user = this.userPool.getCurrentUser();
    if (user) {
      return new Promise<string>((resolve, reject) => {
        user.getSession((err: any, session: CognitoUserSession) => {
          if (err) {
            reject(err);
          } else {
            resolve(session.getAccessToken().getJwtToken());
          }
        });
      });
    } else {
      return null;
    }
  }

  public async verifyUserExistence(username: string) {
    // `https://alpha.auth.api.getfluxpay.com/auth`
    const response = await this.axiosInstance.get(
      `https://alpha.auth.api.getfluxpay.com/auth`,
      {
        headers: {
          Authorization: `${await this.getAccessToken()}`,
        },
        params: {
          username: username,
        },
      }
    );
    return response.data;
  }

  public async verifyProductExistence(productId: string) {
    const response = await this.axiosInstance.get(
      `https://alpha.product.api.getfluxpay.com/products/${productId}`,
      {
        headers: {
          Authorization: `${await this.getAccessToken()}`,
        },
      }
    );
    return response.data;
  }

  public async sendUserConsent(userId: string, consent: boolean) {
    const response = await this.axiosInstance.post(
      `https://alpha.credit.api.getfluxpay.com/consent`,
      {
        userId: userId,
        consent: consent,
      },
      {
        headers: {
          Authorization: `${await this.getAccessToken()}`,
        },
      }
    );
    return response.data;
  }

  public async createCreditApplication(application: Invoice) {
    // alpha.invoice.api.getfluxpay.com
    const response = await this.axiosInstance.post(
      `https://alpha.invoice.api.getfluxpay.com/invoice`,
      application,
      {
        headers: {
          Authorization: `${await this.getAccessToken()}`,
        },
      }
    );
  }

  public async getInvoices(userId: string, role: string) {
    const response = await this.axiosInstance.get(
      `https://alpha.invoice.api.getfluxpay.com/invoice`,
      {
        headers: {
          Authorization: `${await this.getAccessToken()}`,
        },
        params: {
          userId: userId,
          role: role,
        },
      }
    );
    return response.data;
  }
}
