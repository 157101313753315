import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";

export const COGNITO_CONFIG = {
  REGION: "us-east-1",
  USER_POOL_ID: "us-east-1_ih0Ifc3JM",
  USER_POOL_APP_CLIENT_ID: "3tbg06lfamt6jm5skmg1vn6euf",
};

const poolData = {
  UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
  ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
};

export const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);

export const authenticateAccount = (
  username: string,
  password: string,
  onSuccess: (result: AmazonCognitoIdentity.CognitoUserSession) => void,
  onFailure: (err: any) => void
) => {
  const authenticationData = {
    Username: username,
    Password: password,
  };
  const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
    authenticationData
  );

  const userData = {
    Username: authenticationData.Username,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: function (result) {
      onSuccess(result);
    },
    onFailure: function (err) {
      onFailure(err);
    },
  });
};

export const createAccount = (
  username: string,
  email: string,
  password: string,
  onSuccess: (user: any) => void,
  onFailure: (err: any) => void
) => {
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const attributeList = [];
  const dataEmail = {
    Name: "email",
    Value: email,
  };
  const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(
    dataEmail
  );
  attributeList.push(attributeEmail);
  userPool.signUp(
    username,
    password,
    attributeList,
    [],
    function (err, result) {
      if (err) {
        onFailure(err);
        return;
      }
      if (!result) {
        onFailure("No result");
        return;
      }
      const cognitoUser = result.user;
      onSuccess(cognitoUser);
    }
  );
};

export const confirmAccount = (
  username: string,
  code: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const poolData = {
    UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
    ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.confirmRegistration(code, true, function (err, result) {
    if (err) {
      onFailure(err);
      return;
    }
    onSuccess(result);
    return result;
  });
};

export const signOut = () => {
  const poolData = {
    UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
    ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
};

export const forgotPassword = (
  username: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.forgotPassword({
    onSuccess: function (result) {
      onSuccess(result);
    },
    onFailure: function (err) {
      onFailure(err);
    },
  });
};

export const confirmForgotPassword = (
  username: string,
  code: string,
  newPassword: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.confirmPassword(code, newPassword, {
    onSuccess: function () {
      onSuccess("Password reset complete!");
    },
    onFailure: function (err) {
      onFailure(err);
    },
  });
};

export const retrieveUserAttributes = (
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        onFailure(err);
        return;
      }
      cognitoUser.getUserAttributes(function (err, result) {
        if (err) {
          onFailure(err);
          return;
        }
        onSuccess(result);
      });
    });
  }
  onFailure("No user available");
};

export const resendConfirmationCode = (
  username: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const userData = {
    Username: username,
    Pool: userPool,
  };
  const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
  cognitoUser.resendConfirmationCode(function (err, result) {
    if (err) {
      onFailure(err);
      return;
    }
    onSuccess(result);
  });
};

export interface IAttribute {
  Name: string;
  Value: string;
}

export const updateUserAttributes = (
  attributes: IAttribute[],
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        onFailure(err);
        return;
      }
      cognitoUser.updateAttributes(attributes, function (err, result) {
        if (err) {
          onFailure(err);
          return;
        }
        onSuccess(result);
      });
    });
  }
  onFailure("No user available");
};

export const changePassword = (
  oldPassword: string,
  newPassword: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        onFailure(err);
        return;
      }
      cognitoUser.changePassword(
        oldPassword,
        newPassword,
        function (err, result) {
          if (err) {
            onFailure(err);
            return;
          }
          onSuccess(result);
        }
      );
    });
  }
  onFailure("No user available");
};

export const changeEmail = (
  newEmail: string,
  onSuccess: (result: any) => void,
  onFailure: (err: any) => void
) => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.getSession(function (err: any, session: any) {
      if (err) {
        onFailure(err);
        return;
      }
      cognitoUser.updateAttributes(
        [
          {
            Name: "email",
            Value: newEmail,
          },
        ],
        function (err, result) {
          if (err) {
            onFailure(err);
            return;
          }
          onSuccess(result);
        }
      );
    });
  }
  onFailure("No user available");
};

export const getUserRole = (
  onSuccess: (role: string) => void,
  redirectCallback: () => void,
  onFailure: (err: any) => void
) => {
  const user = userPool.getCurrentUser();
  if (!user) {
    redirectCallback();
  } else {
    user.getSession((err: any, session: any) => {
      if (err) {
        redirectCallback();
      } else {
        user.getUserAttributes((err, attributes) => {
          if (err) {
            console.log(err);
            return;
          }
          if (attributes) {
            const roleAttribute = attributes.find(
              (attribute) => attribute.getName() === "custom:role"
            );
            if (roleAttribute) {
              onSuccess(roleAttribute.getValue());
            }
          }
        });
      }
    });
  }
  onFailure("No user available");
};
