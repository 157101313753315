import {
  AppLayout,
  BreadcrumbGroup,
  ButtonDropdown,
  ColumnLayout,
  Container,
  ContentLayout,
  Header,
  SpaceBetween,
  StatusIndicator,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { userPool } from "auth/Cognito";
import { ValueWithLabel } from "common/ValueWithLabel";
import { FunctionComponent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "store/store";
import { Status } from "./Model";
import { VendorInfo } from "./VendorInfo";
import { Role } from "profile/Interface";

export const InvoiceDetail: FunctionComponent = () => {
  const { id } = useParams();
  const invoices = useAppSelector((state) => state.invoice.invoices);
  const invoice = invoices.find((invoice) => invoice.invoiceId === id);
  const [userRole, setUserRole] = useState("");
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const cognitoUser = userPool.getCurrentUser();
    if (!cognitoUser) {
      window.location.href = "#/sign-on";
    } else {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          window.location.href = "#/sign-on";
        } else {
          cognitoUser.getUserAttributes((err, attributes) => {
            if (err) {
              console.log(err);
              return;
            }
            if (attributes) {
              const roleAttribute = attributes.find(
                (attribute) => attribute.getName() === "custom:role"
              );
              if (roleAttribute) {
                setUserRole(roleAttribute.getValue());
              }
            }
          });
        }
      });
    }
  }, []);

  const createStatusIndicator = (status: string) => {
    switch (status) {
      case Status.VERIFIED:
        return <StatusIndicator type="success">{status}</StatusIndicator>;
      case Status.DENIED:
        return <StatusIndicator type="error">{status}</StatusIndicator>;
      default:
        return <StatusIndicator type="pending">{status}</StatusIndicator>;
    }
  };

  const buyerActions = [{ text: "Export", id: "export", disabled: false }];

  const sellerActions = [
    { text: "Approve", id: "approve", disabled: false },
    { text: "Export", id: "export", disabled: false },
  ];

  const [appliedActions, setAppliedActions] = useState([] as any);

  useEffect(() => {
    if (userRole === Role.BUYER) {
      setAppliedActions(buyerActions);
    } else if (userRole === Role.SELLER) {
      setAppliedActions(sellerActions);
    }
  }, [userRole]);

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Invoice", href: "#/invoice" },
            { text: "Detail", href: `#/invoice/detail/${id}` },
            // { text: "Service", href: "#" },
          ]}
        />
      }
      navigation={<FluxSideNavigation activeHref="#/invoice" />}
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                <ButtonDropdown
                  items={appliedActions}
                  onItemClick={(item) => console.log(item)}
                >
                  Actions
                </ButtonDropdown>
              }
            >
              #{id}
            </Header>
          }
        >
          <SpaceBetween size="l">
            <Container
              header={<Header variant="h2">General Information</Header>}
            >
              <ColumnLayout columns={3} variant="text-grid">
                <SpaceBetween size="m">
                  <ValueWithLabel label="Invoice Number">
                    {invoice?.invoiceId}
                  </ValueWithLabel>
                  <ValueWithLabel label="Status">
                    {createStatusIndicator(invoice?.status || "")}
                  </ValueWithLabel>
                </SpaceBetween>

                <SpaceBetween size="m">
                  <ValueWithLabel label="Date of Issue">
                    {invoice?.dateOfIssue}
                  </ValueWithLabel>
                  <ValueWithLabel label="Due Date">
                    {invoice?.dateOfDue}
                  </ValueWithLabel>
                </SpaceBetween>

                <SpaceBetween size="m">
                  <ValueWithLabel label="Unit Price">
                    {invoice?.unitPrice}
                  </ValueWithLabel>
                  <ValueWithLabel label="Quantity">
                    {invoice?.quantity}
                  </ValueWithLabel>
                </SpaceBetween>
              </ColumnLayout>
            </Container>
            <Container header={<Header variant="h2">Buyer Information</Header>}>
              <VendorInfo vendorInfo={invoice?.buyerInfo || {}} />
            </Container>
            <Container
              header={<Header variant="h2">Seller Information</Header>}
            >
              <VendorInfo vendorInfo={invoice?.sellerInfo || {}} />
            </Container>
            <Container
              header={<Header variant="h2">Product Information</Header>}
            ></Container>
          </SpaceBetween>
        </ContentLayout>
      }
    />
  );
};
