import { createSlice } from "@reduxjs/toolkit";

export interface SettingState {
  darkMode: boolean;
  compactMode: boolean;
}

const initialState: SettingState = {
  darkMode: false,
  compactMode: false,
};

const settingSlice = createSlice({
  name: "setting",
  initialState: initialState,
  reducers: {
    setDarkMode(state, action) {
      state.darkMode = action.payload;
    },
    setCompactMode(state, action) {
      state.compactMode = action.payload;
    },
  },
});

export const settingActions = settingSlice.actions;
const settingReducer = settingSlice.reducer;
export default settingReducer;
