import React, { FunctionComponent, useEffect } from "react";
import { Input, TopNavigation } from "@cloudscape-design/components";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import messages from "@cloudscape-design/components/i18n/messages/all.en";
import { HashRouter, Route, Routes } from "react-router-dom";
import Home from "Home";
import { FluxSignOnView } from "auth/FluxSignOn";
import ProfileMain from "profile/ProfileMain";
import { store, useAppDispatch, useAppSelector } from "store/store";
import { RecoverPassword } from "auth/FluxRecover";
import { COGNITO_CONFIG, retrieveUserAttributes, signOut } from "auth/Cognito";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { userActions } from "store/slices/user-slice";
import logo from "./logo.svg";
import Security from "profile/Security";
import { Settings } from "Settings";
import { ResetPassword } from "auth/FluxResetPassword";
import InvoiceMain from "invoice/InvoiceMain";
import { CreditApplication } from "invoice/CreditApplication";
import { InvoiceDetail } from "invoice/InvoiceDetail";
import { ProductMain } from "product/ProductMain";
import { ProductCreator } from "product/ProductCreator";

const LOCALE = "en";

const profileActions = [
  { id: "profile", text: "Profile", href: "#/profile" },
  { id: "preferences", text: "Preferences" },
  { id: "security", text: "Security", href: "#/security" },
  {
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "documentation",
        text: "Documentation",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "feedback",
        text: "Feedback",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      { id: "support", text: "Customer support" },
    ],
  },
  {
    id: "signout",
    text: "Sign out",
  },
];

const unsignedProfileActions = [
  {
    id: "support-group",
    text: "Support",
    items: [
      {
        id: "documentation",
        text: "Documentation",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      {
        id: "feedback",
        text: "Feedback",
        href: "#",
        external: true,
        externalIconAriaLabel: " (opens in new tab)",
      },
      { id: "support", text: "Customer support" },
    ],
  },
  { id: "signin", text: "Sign in", href: "#/sign-on" },
];

export default function () {
  const [searchValue, setSearchValue] = React.useState("");

  const poolData = {
    UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
    ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
  };
  const userPool = new CognitoUserPool(poolData);
  const [cognitoUser] = React.useState(userPool.getCurrentUser());

  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);
  const userEmail = useAppSelector((state) => state.user.email);
  const [displayedActions, setDisplayedActions] = React.useState(
    unsignedProfileActions as any[]
  );

  useEffect(() => {
    if (!username) {
      setDisplayedActions(unsignedProfileActions);
    } else {
      setDisplayedActions(profileActions);
    }
  }, [username]);

  useEffect(() => {
    if (cognitoUser) {
      console.log(cognitoUser);
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        dispatch(userActions.setUsername(cognitoUser.getUsername()));
      });
      retrieveUserAttributes(
        (result) => {
          result.forEach((attribute: { Name: string; Value: any }) => {
            if (attribute.Name === "email") {
              dispatch(userActions.setEmail(attribute.Value));
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [cognitoUser]);

  return (
    <>
      <TopNavigation
        identity={{
          href: "#",
          title: "Flux Pay",
          logo: { src: logo, alt: "Service name logo" },
        }}
        search={
          <Input
            ariaLabel="Input field"
            clearAriaLabel="Clear"
            value={searchValue}
            type="search"
            placeholder="Search"
            onChange={({ detail }) => setSearchValue(detail.value)}
          />
        }
        utilities={[
          // {
          //   type: "button",
          //   iconName: "notification",
          //   ariaLabel: "Notifications",
          //   badge: true,
          //   disableUtilityCollapse: true,
          // },
          {
            type: "button",
            iconName: "settings",
            title: "Settings",
            ariaLabel: "Settings",
            href: "#/settings",
          },
          {
            type: "menu-dropdown",
            text: username ? username : " - ",
            description: userEmail ? userEmail : undefined,
            iconName: "user-profile",
            items: displayedActions,
            onItemClick: (event) => {
              if (event.detail.id === "signout") {
                signOut();
                dispatch(userActions.signOut());
                window.location.href = "#/sign-on";
              }
            },
          },
        ]}
      />
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <HashRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-on" element={<FluxSignOnView />} />
            <Route path="/profile" element={<ProfileMain />} />
            <Route path="/recover" element={<RecoverPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/security" element={<Security />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/invoice">
              <Route path="" element={<InvoiceMain />} />
              <Route path="detail">
                <Route path=":id" element={<InvoiceDetail />} />
              </Route>
              <Route path="application" element={<CreditApplication />}>
                <Route path=":id" element={<CreditApplication />} />
              </Route>
            </Route>
            <Route path="/product">
              <Route path="" element={<ProductMain />} />
              <Route path="detail">
                <Route path=":id" element={<div>Product Detail</div>} />
              </Route>
              <Route path="create" element={<ProductCreator />} />
            </Route>
          </Routes>
        </HashRouter>
      </I18nProvider>
    </>
  );
}

// Browse Seller & Products
