import { FunctionComponent, useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  HelpPanel,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { confirmForgotPassword, forgotPassword } from "./Cognito";

export const RecoverPassword: FunctionComponent = () => {
  const [username, setUsername] = useState("");

  const [codeSent, setCodeSent] = useState(false);

  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);

  const handleSendCode = () => {
    forgotPassword(
      username,
      (result) => {
        console.log(result);
        setCodeSent(true);
        setNotifications([
          {
            type: "info",
            dismissible: true,
            content: "A code has been sent to your email.",
            id: "code_sent",
          },
        ]);
      },
      (err) => {
        setNotifications([
          {
            type: "error",
            dismissible: true,
            content: err.message || JSON.stringify(err),
            id: "error_sending_code",
          },
        ]);
      }
    );
  };

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setNotifications([
        {
          type: "error",
          dismissible: true,
          content: "Passwords do not match.",
          id: "passwords_do_not_match",
        },
      ]);
      return;
    }
    confirmForgotPassword(
      username,
      code,
      password,
      (result) => {
        console.log(result);
        setCodeSent(false);
        setNotifications([
          {
            type: "success",
            dismissible: true,
            content: "Your password has been reset.",
            id: "password_reset",
          },
        ]);
      },
      (err) => {
        setNotifications([
          {
            type: "error",
            dismissible: true,
            content: err.message || JSON.stringify(err),
            id: "error_resetting_password",
          },
        ]);
      }
    );
  };

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Sign On", href: "#/sign-on" }]} />
      }
      notifications={<Flashbar items={notifications} />}
      navigation={<FluxSideNavigation activeHref="#/sign-on" />}
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={codeSent ? handleResetPassword : handleSendCode}
                >
                  {codeSent ? "Reset Password" : "Send Code"}
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">Reset Your Password</Header>}
          >
            <Container>
              <SpaceBetween direction="vertical" size="l">
                {!codeSent ? (
                  <>
                    <FormField label="Username">
                      <Input
                        value={username}
                        onChange={(e) => setUsername(e.detail.value)}
                        placeholder="Username"
                      />
                    </FormField>
                  </>
                ) : (
                  <>
                    <FormField label="Code">
                      <Input
                        value={code}
                        onChange={(e) => setCode(e.detail.value)}
                        placeholder="Code"
                      />
                    </FormField>
                    <FormField label="New Password">
                      <Input
                        value={password}
                        onChange={(e) => setPassword(e.detail.value)}
                        placeholder="New Password"
                        type="password"
                      />
                    </FormField>
                    <FormField label="Confirm New Password">
                      <Input
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.detail.value)}
                        placeholder="Confirm New Password"
                        type="password"
                      />
                    </FormField>
                  </>
                )}
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      }
    />
  );
};
