import {
  AppLayout,
  Box,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
  Link,
  SpaceBetween,
  SplitPanel,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { FunctionComponent, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/store";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import "@aws-amplify/ui-react/styles.css";
import { COGNITO_CONFIG, retrieveUserAttributes } from "auth/Cognito";
import { userActions } from "store/slices/user-slice";
import { ValueWithLabel } from "common/ValueWithLabel";



const Security = () => {
  const poolData = {
    UserPoolId: COGNITO_CONFIG.USER_POOL_ID,
    ClientId: COGNITO_CONFIG.USER_POOL_APP_CLIENT_ID,
  };
  const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
  const cognitoUser = userPool.getCurrentUser();

  const dispatch = useAppDispatch();
  const username = useAppSelector((state) => state.user.username);
  const userEmail = useAppSelector((state) => state.user.email);

  useEffect(() => {
    if (cognitoUser) {
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        dispatch(userActions.setUsername(cognitoUser.getUsername()));
      });
      retrieveUserAttributes(
        (result) => {
          result.forEach((attribute: { Name: string; Value: any }) => {
            if (attribute.Name === "email") {
              dispatch(userActions.setEmail(attribute.Value));
            }
          });
        },
        (err) => {
          console.log(err);
        }
      );
    } else {
      window.location.href = "#/sign-on";
    }
  }, [cognitoUser]);

  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/profile" />}
      notifications={
        <Flashbar
          items={[
            {
              type: "info",
              dismissible: true,
              content: "This is dashboard is under construction.",
              id: "message_1",
            },
          ]}
        />
      }
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <ContentLayout
          header={
            <Header variant="h1" info={<Link variant="info">Info</Link>}>
              Security
            </Header>
          }
        >
          <Container>
            <ColumnLayout columns={2} variant="text-grid">
              <SpaceBetween size="l">
                <ValueWithLabel label="Username">{username}</ValueWithLabel>
                <ValueWithLabel label="Email">{userEmail}</ValueWithLabel>
              </SpaceBetween>
              <SpaceBetween size="l">
                {/* <ProgressBar
                  variant="key-value"
                  label="Status"
                  value={37}
                  description="Update in progress"
                /> */}
                <ValueWithLabel label="Credentials">
                  <Link href="#/reset-password" variant="primary">
                    Reset Password
                  </Link>
                </ValueWithLabel>
              </SpaceBetween>
            </ColumnLayout>
          </Container>
        </ContentLayout>
      }
      splitPanel={
        <SplitPanel header="Split panel header">Split panel content</SplitPanel>
      }
    />
  );
};

export default Security;
