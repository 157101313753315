import {
  AppLayout,
  BreadcrumbGroup,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
  Link,
  SideNavigation,
  SplitPanel,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";

const Home = () => {
  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup
          items={[
            { text: "Home", href: "#" },
            // { text: "Service", href: "#" },
          ]}
        />
      }
      navigation={<FluxSideNavigation activeHref="#" />}
      notifications={
        <Flashbar
          items={[
            {
              type: "info",
              dismissible: true,
              content: "This is dashboard is under construction.",
              id: "message_1",
            },
          ]}
        />
      }
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <ContentLayout
          header={
            <Header variant="h1" info={<Link variant="info">Info</Link>}>
              Welcome to Flux!
            </Header>
          }
        >
          <Container
            header={
              <Header variant="h2" description="Container description">
                Container header
              </Header>
            }
          >
            <div className="contentPlaceholder" />
          </Container>
        </ContentLayout>
      }
      splitPanel={
        <SplitPanel header="Split panel header">Split panel content</SplitPanel>
      }
    />
  );
};

export default Home;
