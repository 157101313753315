import { FunctionComponent, useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  HelpPanel,
  Input,
  SpaceBetween,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { changePassword, userPool } from "./Cognito";

export const ResetPassword: FunctionComponent = () => {
  const cognitoUser = userPool.getCurrentUser();

  useEffect(() => {
    if (!cognitoUser) {
      window.location.href = "#/sign-on";
    }
  }, [cognitoUser]);

  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Sign On", href: "#/sign-on" }]} />
      }
      notifications={<Flashbar items={notifications} />}
      navigation={<FluxSideNavigation activeHref="#/sign-on" />}
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  formAction="none"
                  variant="link"
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    changePassword(
                      oldPassword,
                      newPassword,
                      (result) => {
                        setNotifications([
                          {
                            type: "success",
                            dismissible: true,
                            content: "Password changed successfully.",
                            id: "password_changed",
                          },
                        ]);
                      },
                      (err) => {
                        setNotifications([
                          {
                            type: "error",
                            dismissible: true,
                            content: err.message || JSON.stringify(err),
                            id: "error_changing_password",
                          },
                        ]);
                      }
                    );
                  }}
                >
                  Reset Password
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">Reset Your Password</Header>}
          >
            <Container>
              <SpaceBetween direction="vertical" size="l">
                <FormField label="Old Password">
                  <Input
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.detail.value)}
                    placeholder="Old Password"
                    type="password"
                  />
                </FormField>
                <FormField label="New Password">
                  <Input
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.detail.value)}
                    placeholder="New Password"
                    type="password"
                  />
                </FormField>
                <FormField label="Confirm New Password">
                  <Input
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.detail.value)}
                    placeholder="Confirm New Password"
                    type="password"
                  />
                </FormField>
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      }
    />
  );
};
