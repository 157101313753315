import { VendorInfo } from "./VendorInfo";

export interface Invoice {
  invoiceId?: string;
  productId?: string;
  dateOfIssue?: string;
  quantity?: number;
  unitPrice?: number;
  dateOfDue?: string;
  buyerId?: string;
  sellerId?: string;
  buyerInfo?: VendorInfo;
  sellerInfo?: VendorInfo;
  status?: Status;
  paymentSchedule?: Record<string, string>;
  buyerBankInfo?: Record<string, string>;
  taxes?: Record<string, string>;
  discounts?: Record<string, string>;
  latePenalties?: Record<string, string>;
  notes?: Record<string, string>;
}

export enum Status {
  UNVERIFIED = "UNVERIFIED",
  VERIFIED = "VERIFIED",
  PAID = "PAID",
  OVERDUE = "OVERDUE",
  DENIED = "DENIED",
  DELETED = "DELETED",
  OTHER = "OTHER",
}

export const invoiceFixture: Invoice = {
  invoiceId: "123",
  dateOfIssue: "2022-01-01",
  quantity: 10,
  unitPrice: 100,
  dateOfDue: "2022-02-01",
  status: Status.VERIFIED,
  paymentSchedule: {
    installment1: "2022-02-01",
    installment2: "2022-03-01",
    installment3: "2022-04-01",
  },
  buyerInfo: {
    userId: "123",
    businessName: "XYZ Inc",
    businessAddress: "123 Oak St",
    contactName: "John Doe",
    taxId: "123456789",
    contactEmail: "john.doe@xyz.com",
    contactPhone: "123-456-7890",
  },
  sellerInfo: {
    userId: "456",
    businessName: "ABC Corp",
    businessAddress: "123 Main St",
    contactName: "Jane Smith",
    taxId: "987654321",
    contactEmail: "jane.smith@abc.com",
    contactPhone: "123-456-7890",
  },
  buyerBankInfo: {
    bankName: "ABC Bank",
    accountNumber: "1234567890",
    routingNumber: "987654321",
  },
  taxes: {
    vat: "10%",
    salesTax: "5%",
  },
  discounts: {
    earlyPayment: "5%",
    bulkOrder: "10%",
  },
  latePenalties: {
    dailyRate: "1%",
    maximumRate: "10%",
  },
  notes: {
    specialInstructions: "Handle with care",
    additionalInfo: "Please include invoice number on payment",
  },
  productId: "123",
  buyerId: "123",
  sellerId: "456",
};
