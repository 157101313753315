import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { useEffect, useState } from "react";
import "@aws-amplify/ui-react/styles.css";
import { InvoiceTable } from "./InvoiceTable";
import { getUserRole, userPool } from "auth/Cognito";
import { Role } from "profile/Interface";
import { ApiClient } from "api/ApiClient";
import { useAppDispatch, useAppSelector } from "store/store";
import { invoiceActions } from "store/slices/invoice-slice";

const InvoiceMain = () => {
  const [notifications, setNotifications] = useState([]);
  const [role, setRole] = useState("");
  const [userId, setUserId] = useState("");

  const invoices = useAppSelector((state) => state.invoice.invoices);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const user = userPool.getCurrentUser();
    if (!user) {
      window.location.href = "#/sign-on";
    } else {
      setUserId(user.getUsername());
      getUserRole(
        (role) => setRole(role),
        () => (window.location.href = "#/sign-on"),
        (err) => console.log(err)
      );
    }
  }, []);

  useEffect(() => {
    if (role.length > 0 && userId.length > 0) {
      setLoading(true);
      ApiClient.getInstance()
        .getInvoices(userId, role)
        .then((response) => {
          console.log(response);
          setLoading(false);
          dispatch(invoiceActions.setInvoices(response));
        });
    }
  }, [role, userId]);

  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/invoice" />}
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Invoice", href: "#/invoice" }]} />
      }
      notifications={<Flashbar items={notifications} />}
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <ContentLayout
          header={
            <Header
              variant="h1"
              actions={
                role === Role.BUYER && (
                  <Button variant="primary" href="#/invoice/application">
                    New Credit Application
                  </Button>
                )
              }
            >
              Overview
            </Header>
          }
        >
          <InvoiceTable items={invoices} loading={loading} />
        </ContentLayout>
      }
    />
  );
};

export default InvoiceMain;
