import { FunctionComponent, useEffect, useState } from "react";
import {
  AppLayout,
  BreadcrumbGroup,
  Button,
  Container,
  ExpandableSection,
  Flashbar,
  FlashbarProps,
  Form,
  FormField,
  Header,
  HelpPanel,
  Input,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import {
  authenticateAccount,
  confirmAccount,
  createAccount,
  retrieveUserAttributes,
  userPool,
} from "./Cognito";
import { useAppDispatch } from "store/store";
import { userActions } from "store/slices/user-slice";

export const FluxSignOnView: FunctionComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [email, setEmail] = useState("");
  const [password2, setPassword2] = useState("");
  const [password3, setPassword3] = useState("");
  const [confirmCode, setConfirmCode] = useState("");

  const [showNewUser, setShowNewUser] = useState(false);
  const [pendingConfirmation, setPendingConfirmation] = useState(false);

  const [notifications, setNotifications] = useState<
    FlashbarProps.MessageDefinition[]
  >([]);
  const [authenticated, setAuthenticated] = useState(false);

  const cognitoUser = userPool.getCurrentUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (cognitoUser) {
      cognitoUser.getSession(function (err: any, session: any) {
        if (err) {
          alert(err.message || JSON.stringify(err));
          return;
        }
        setAuthenticated(true);
      });
    }
  }, [cognitoUser]);

  useEffect(() => {
    if (authenticated) {
      window.location.href = "#/";
    }
  }, [authenticated]);

  return (
    <AppLayout
      breadcrumbs={
        <BreadcrumbGroup items={[{ text: "Sign On", href: "#/sign-on" }]} />
      }
      notifications={<Flashbar items={notifications} />}
      navigation={<FluxSideNavigation activeHref="#/sign-on" />}
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <form onSubmit={(e) => e.preventDefault()}>
          <Form
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button formAction="none" variant="link" href="#">
                  Cancel
                </Button>
              </SpaceBetween>
            }
            header={<Header variant="h1">Sign In or Create New Account</Header>}
          >
            <Container>
              <SpaceBetween direction="vertical" size="l">
                <ExpandableSection
                  headerText="Returning Customer"
                  expanded={!showNewUser}
                  onChange={() => setShowNewUser(!showNewUser)}
                >
                  <SpaceBetween direction="vertical" size="m">
                    <FormField label="Username">
                      <Input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.detail.value)}
                      />
                    </FormField>
                    <FormField label="Password">
                      <Input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.detail.value)}
                      />
                    </FormField>
                    <div>
                      <Button
                        variant="primary"
                        onClick={() => {
                          authenticateAccount(
                            username,
                            password,
                            (result) => {
                              setAuthenticated(true);
                              dispatch(userActions.setUsername(username));
                              retrieveUserAttributes(
                                (result) => {
                                  result.forEach(
                                    (attribute: {
                                      Name: string;
                                      Value: any;
                                    }) => {
                                      if (attribute.Name === "email") {
                                        dispatch(
                                          userActions.setEmail(attribute.Value)
                                        );
                                      }
                                    }
                                  );
                                },
                                (err) => {
                                  console.log(err);
                                }
                              );
                            },
                            (err) => {
                              setNotifications([
                                {
                                  type: "error",
                                  dismissible: true,
                                  content: err.message || JSON.stringify(err),
                                  id: "authenticate_account_error",
                                },
                              ]);
                            }
                          );
                        }}
                      >
                        Sign In
                      </Button>
                      <div
                        style={{ display: "inline-block", marginLeft: "1rem" }}
                      >
                        <Link href="#/recover">Forgot Password?</Link>
                      </div>
                    </div>
                  </SpaceBetween>
                </ExpandableSection>
                <ExpandableSection
                  headerText="New Customer"
                  expanded={showNewUser}
                  onChange={() => setShowNewUser(!showNewUser)}
                >
                  <SpaceBetween direction="vertical" size="m">
                    {!pendingConfirmation ? (
                      <>
                        <p>
                          By creating an account with our store, you will be
                          able to move through the checkout process faster,
                          store multiple shipping addresses, view and track your
                          orders in your account and more.
                        </p>
                        <FormField label="Username">
                          <Input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.detail.value)}
                          />
                        </FormField>
                        <FormField label="Email Address">
                          <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.detail.value)}
                          />
                        </FormField>
                        <FormField label="Password">
                          <Input
                            type="password"
                            value={password2}
                            onChange={(e) => setPassword2(e.detail.value)}
                          />
                        </FormField>
                        <FormField label="Confirm Password">
                          <Input
                            type="password"
                            value={password3}
                            onChange={(e) => setPassword3(e.detail.value)}
                          />
                        </FormField>

                        <Button
                          variant="primary"
                          onClick={() => {
                            createAccount(
                              username,
                              email,
                              password2,
                              (user) => {
                                setNotifications([
                                  {
                                    type: "info",
                                    dismissible: true,
                                    content:
                                      "A confirmation code has been sent to your email address.",
                                    id: "confirm_account",
                                  },
                                ]);
                                setPendingConfirmation(true);
                              },
                              (err) => {
                                setNotifications([
                                  {
                                    type: "error",
                                    dismissible: true,
                                    content: err.message || JSON.stringify(err),
                                    id: "create_account_error",
                                  },
                                ]);
                              }
                            );
                          }}
                        >
                          Create Account
                        </Button>
                      </>
                    ) : (
                      <>
                        <FormField label="Confirmation Code">
                          <Input
                            type="text"
                            value={confirmCode}
                            onChange={(e) => setConfirmCode(e.detail.value)}
                          />
                        </FormField>
                        <Button
                          variant="primary"
                          onClick={() => {
                            confirmAccount(
                              username,
                              confirmCode,
                              (result) => {
                                setNotifications([
                                  {
                                    type: "success",
                                    dismissible: true,
                                    content: "Account confirmed.",
                                    id: "confirm_account",
                                  },
                                ]);
                                setPendingConfirmation(false);
                                setShowNewUser(false);
                              },
                              (err) => {
                                setNotifications([
                                  {
                                    type: "error",
                                    dismissible: true,
                                    content: err.message || JSON.stringify(err),
                                    id: "confirm_account_error",
                                  },
                                ]);
                              }
                            );
                          }}
                        >
                          Confirm
                        </Button>
                      </>
                    )}
                  </SpaceBetween>
                </ExpandableSection>
              </SpaceBetween>
            </Container>
          </Form>
        </form>
      }
    />
  );
};
