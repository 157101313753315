// Import necessary dependencies
import { useState } from "react";
import {
  applyMode,
  Mode,
  applyDensity,
  Density,
} from "@cloudscape-design/global-styles";
import {
  AppLayout,
  ColumnLayout,
  Container,
  ContentLayout,
  Flashbar,
  Header,
  HelpPanel,
  Link,
  SpaceBetween,
  SplitPanel,
  Toggle,
} from "@cloudscape-design/components";
import FluxSideNavigation from "FluxSideNavigation";
import { useAppDispatch, useAppSelector } from "store/store";
import { settingActions } from "store/slices/setting-slice";

// Create the Settings component
export const Settings = () => {
  const isDarkMode = useAppSelector((state) => state.setting.darkMode);
  const isCompactMode = useAppSelector((state) => state.setting.compactMode);

  const dispatch = useAppDispatch();

  return (
    <AppLayout
      navigation={<FluxSideNavigation activeHref="#/settings" />}
      notifications={
        <Flashbar
          items={[
            {
              type: "info",
              dismissible: true,
              content: "This is dashboard is under construction.",
              id: "message_1",
            },
          ]}
        />
      }
      tools={<HelpPanel header={<h2>Overview</h2>}>Help content</HelpPanel>}
      content={
        <ContentLayout
          header={
            <Header variant="h1" info={<Link variant="info">Info</Link>}>
              Settings
            </Header>
          }
        >
          <Container>
            <ColumnLayout columns={2} variant="text-grid">
              <Toggle
                onChange={({ detail }) => {
                  dispatch(settingActions.setDarkMode(detail.checked));
                  applyMode(detail.checked ? Mode.Dark : Mode.Light);
                }}
                checked={isDarkMode}
              >
                Dark Mode
              </Toggle>
              <Toggle
                onChange={({ detail }) => {
                  dispatch(settingActions.setCompactMode(detail.checked));
                  applyDensity(
                    detail.checked ? Density.Compact : Density.Comfortable
                  );
                }}
                checked={isCompactMode}
              >
                Compact Mode
              </Toggle>
            </ColumnLayout>
          </Container>
        </ContentLayout>
      }
      splitPanel={
        <SplitPanel header="Split panel header">Split panel content</SplitPanel>
      }
    />
  );
};
