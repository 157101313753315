import { ColumnLayout, SpaceBetween } from "@cloudscape-design/components";
import { ValueWithLabel } from "common/ValueWithLabel";
import { FunctionComponent } from "react";

export interface VendorInfo {
  userId: string;
  businessName: string;
  businessAddress: string;
  contactName: string;
  taxId: string;
  contactEmail: string;
  contactPhone: string;
}

export const VendorInfo: FunctionComponent<{
  vendorInfo: any;
}> = ({ vendorInfo }) => {
  return (
    <ColumnLayout columns={3} variant="text-grid">
      <SpaceBetween size="l">
        <ValueWithLabel label="Business Name">
          {vendorInfo["custom:businessName"] || vendorInfo["businessName"]}
        </ValueWithLabel>
        <ValueWithLabel label="Business Address">
          {vendorInfo["custom:businessAddress"] ||
            vendorInfo["businessAddress"]}
        </ValueWithLabel>
      </SpaceBetween>
      <SpaceBetween size="l">
        <ValueWithLabel label="Contact Name">
          {vendorInfo["custom:contactName"] || vendorInfo["contactName"]}
        </ValueWithLabel>
        <ValueWithLabel label="Tax ID">
          {vendorInfo["custom:taxId"] || vendorInfo["taxId"]}
        </ValueWithLabel>
      </SpaceBetween>
      <SpaceBetween size="l">
        <ValueWithLabel label="Contact Email">
          {vendorInfo["custom:contactEmail"] || vendorInfo["contactEmail"]}
        </ValueWithLabel>
        <ValueWithLabel label="Contact Phone">
          {vendorInfo["custom:contactPhone"] || vendorInfo["contactPhone"]}
        </ValueWithLabel>
      </SpaceBetween>
    </ColumnLayout>
  );
};
