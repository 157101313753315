import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import { Link } from "@cloudscape-design/components";

export interface Product {
  sellerId: string;
  productId: string;
  name: string;
  description: string;
  price: number;
  quantity: number;
  imgUrl: string;
}

export const ProductTable: React.FunctionComponent<{
  items: Product[];
  loading?: boolean;
}> = ({ items, loading }) => {
  const [selectedItems, setSelectedItems] = React.useState<Product[]>([]);
  const [columnDisplay, setColumnDisplay] = React.useState([
    { id: "productId", visible: true },
    { id: "name", visible: true },
    { id: "description", visible: true },
    { id: "price", visible: true },
    { id: "quantity", visible: true },
  ]);
  const [pagesCount, setPagesCount] = React.useState(1);
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);

  return (
    <Table
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      loading={loading}
      columnDefinitions={[
        {
          id: "productId",
          header: "Product ID",
          cell: (item) => (
            <Link href={`#/product/${item.productId}`}>
              {item.productId?.slice(-10)}
            </Link>
          ),
          isRowHeader: true,
        },
        {
          id: "name",
          header: "Name",
          cell: (item) => item.name,
        },
        {
          id: "description",
          header: "Description",
          cell: (item) => item.description,
        },
        {
          id: "price",
          header: "Price",
          cell: (item) => item.price,
        },
        {
          id: "quantity",
          header: "Quantity",
          cell: (item) => item.quantity,
        },
      ]}
      columnDisplay={columnDisplay}
      items={items}
      loadingText="Loading products..."
      selectionType="multi"
      trackBy="productId"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No products</b>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${items.length})`
              : `(${items.length})`
          }
        >
          Products
        </Header>
      }
      pagination={
        <Pagination
          currentPageIndex={currentPageIndex}
          pagesCount={pagesCount}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: columnDisplay,
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 entries" },
              { value: 20, label: "20 entries" },
              { value: 50, label: "50 entries" },
              { value: Number.MAX_VALUE, label: "All entries" },
            ],
          }}
          wrapLinesPreference={{}}
          stripedRowsPreference={{}}
          contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "productId",
                label: "Product ID",
                alwaysVisible: true,
              },
              {
                id: "name",
                label: "Name",
              },
              {
                id: "description",
                label: "Description",
              },
              {
                id: "price",
                label: "Price",
              },
              {
                id: "quantity",
                label: "Quantity",
              },
            ],
          }}
          onConfirm={(preferences) => {
            setColumnDisplay(preferences.detail.contentDisplay as any);
          }}
        />
      }
    />
  );
};
