import { createSlice } from "@reduxjs/toolkit";
import { Invoice, invoiceFixture } from "invoice/Model";

export interface InvoiceState {
  invoices: Invoice[];
}

const initialState: InvoiceState = {
  invoices: [],
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialState,
  reducers: {
    addInvoice(state, action) {
      state.invoices.push(action.payload);
    },
    removeInvoice(state, action) {
      state.invoices = state.invoices.filter(
        (invoice) => invoice.invoiceId !== action.payload
      );
    },
    setInvoices(state, action) {
      state.invoices = action.payload;
    },
  },
});

export const invoiceActions = invoiceSlice.actions;
export const invoiceReducer = invoiceSlice.reducer;
