import * as React from "react";
import Table from "@cloudscape-design/components/table";
import Box from "@cloudscape-design/components/box";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Header from "@cloudscape-design/components/header";
import Pagination from "@cloudscape-design/components/pagination";
import CollectionPreferences from "@cloudscape-design/components/collection-preferences";
import { Link } from "@cloudscape-design/components";
import { Invoice, invoiceFixture } from "./Model";

export const InvoiceTable: React.FunctionComponent<{
  items: Invoice[];
  loading?: boolean;
}> = ({ items, loading }) => {
  const [selectedItems, setSelectedItems] = React.useState<Invoice[]>([]);
  const [columnDisplay, setColumnDisplay] = React.useState([
    { id: "invoiceId", visible: true },
    { id: "dateOfIssue", visible: true },
    { id: "quantity", visible: true },
    { id: "unitPrice", visible: true },
    { id: "dateOfDue", visible: true },
    { id: "status", visible: true },
  ]);
  const [pagesCount, setPagesCount] = React.useState(1);
  const [currentPageIndex, setCurrentPageIndex] = React.useState(1);

  return (
    <Table
      onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
      selectedItems={selectedItems}
      loading={loading}
      columnDefinitions={[
        {
          id: "invoiceId",
          header: "Invoice ID",
          cell: (item) => (
            <Link href={`#/invoice/detail/${item.invoiceId}`}>
              {item.invoiceId?.slice(-10)}
            </Link>
          ),
          isRowHeader: true,
        },
        {
          id: "dateOfIssue",
          header: "Date of issue",
          cell: (item) => item.dateOfIssue,
          sortingField: "dateOfIssue",
        },
        {
          id: "quantity",
          header: "Quantity",
          cell: (item) => item.quantity,
        },
        {
          id: "unitPrice",
          header: "Unit price",
          cell: (item) => item.unitPrice,
        },
        {
          id: "dateOfDue",
          header: "Date of due",
          cell: (item) => item.dateOfDue,
        },
        {
          id: "status",
          header: "Status",
          cell: (item) => item.status,
        },
      ]}
      columnDisplay={columnDisplay}
      items={items}
      loadingText="Loading invoices"
      selectionType="multi"
      trackBy="invoiceId"
      empty={
        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
          <SpaceBetween size="m">
            <b>No invoices</b>
          </SpaceBetween>
        </Box>
      }
      header={
        <Header
          counter={
            selectedItems.length
              ? `(${selectedItems.length}/${items.length})`
              : `(${items.length})`
          }
        >
          Past invoices
        </Header>
      }
      pagination={
        <Pagination
          currentPageIndex={currentPageIndex}
          pagesCount={pagesCount}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            contentDisplay: columnDisplay,
          }}
          pageSizePreference={{
            title: "Page size",
            options: [
              { value: 10, label: "10 entries" },
              { value: 20, label: "20 entries" },
              { value: 50, label: "50 entries" },
              { value: Number.MAX_VALUE, label: "All entries" },
            ],
          }}
          wrapLinesPreference={{}}
          stripedRowsPreference={{}}
          contentDensityPreference={{}}
          contentDisplayPreference={{
            options: [
              {
                id: "invoiceId",
                label: "Invoice ID",
                alwaysVisible: true,
              },
              {
                id: "dateOfIssue",
                label: "Date of issue",
              },
              {
                id: "quantity",
                label: "Quantity",
              },
              {
                id: "unitPrice",
                label: "Unit price",
              },
              {
                id: "dateOfDue",
                label: "Date of due",
              },
              {
                id: "status",
                label: "Status",
              },
            ],
          }}
          onConfirm={(preferences) => {
            setColumnDisplay(preferences.detail.contentDisplay as any);
          }}
        />
      }
    />
  );
};
