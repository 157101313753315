export interface IBasicInfo {
  businessName: string;
  businessAddress: string;
  businessCity: string;
  businessState: string;
  industry: string;
  taxId: string;
  contactName: string;
  contactEmail: string;
  contactPhone: string;
}

export interface IBuyerInfo extends IBasicInfo {
  // primary bank account
  accountNumber: string;
  routingNumber: string;
  // estimated annual revenue
}

export interface ISellerInfo extends IBasicInfo {}

// buyer applies for credit to the seller
export interface ICreditApplication {}

export enum Role {
  BUYER = "Buyer",
  SELLER = "Seller",
}
