import { SideNavigation } from "@cloudscape-design/components";
import { FunctionComponent } from "react";

const FluxSideNavigation: FunctionComponent<{
  activeHref: string;
}> = ({ activeHref }) => {
  return (
    <SideNavigation
      //   header={{
      //     href: "#",
      //     text: "Flux Pay",
      //   }}
      items={[
        { type: "link", text: `Home`, href: `#` },
        { type: "link", text: `Invoice`, href: `#/invoice` },
        { type: "link", text: `Product`, href: `#/product` },
        // {
        //   type: "link",
        //   text: `Account`,
        //   href: `#/account`,
        // },
      ]}
      activeHref={activeHref}
    />
  );
};

export default FluxSideNavigation;
